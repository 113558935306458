
import { defineComponent, onMounted, ref } from 'vue'
import AuthService from './services/AuthService'
import CommonLayout from '@/components/layouts/CommonLayout.vue'
import { useStore } from 'vuex';

export default defineComponent({
  name: 'App',
  components: {
    CommonLayout,
  },
  setup() {
    const isMounted = ref(false);
    const store = useStore();

    onMounted(async () => {
      if (AuthService.isLoggedIn()) {
        await store.dispatch('setup')
      }
      isMounted.value = true;
    })

    return {
      isMounted
    }
  },
})
