
import { computed, defineComponent } from 'vue'
import { MrUiKitNavbar, MrUiKitAlert } from 'mr-ui-kit';
import { useStore } from 'vuex';
import { MainStore } from '@/interfaces';
import { useRoute, useRouter } from 'vue-router';
import AuthService from '@/services/AuthService';
import InlineToast from '@/components/UI/InlineToast.vue';
import Spinner from '@/components/UI/Spinner.vue';

export default defineComponent({
    name: 'CommonLayout',
    components: {
        MrUiKitNavbar,
        InlineToast,
        MrUiKitAlert,
        Spinner
    },
    setup() {
        const route = useRoute();
        const router = useRouter()
        const store = useStore<MainStore>();

        const tabs = computed(() => {
            return store.state.tabs.map(t => ({
                ...t,
                active: route.path === t.path
            }));
        })

        const alert = computed(() => store.state.alert);
        const isLoading = computed(() => store.state.isLoading);

        const showNoProviderToast = computed(() => {
            return !store.getters.haveProvider && route.path.indexOf('login') < 0;
        })

        const onNavigate = (event: any) => {
            if (event.indexOf('logout') >= 0) {
                AuthService.logout();
            } else {
                router.push(event);
            }
        }

        return {
            logoHeader: require("@/assets/logo.png"),
            tabs,
            showNoProviderToast,
            alert,
            isLoading,
            onNavigate
        }
    },
})
