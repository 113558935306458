import { NavbarTab } from '@/interfaces';

export namespace UtilsTab {
    export const USER_TABS: NavbarTab[] = [
        {
            label: 'Fatture',
            path: '/invoices',
            active: false
        },
        {
            label: 'Fornitore',
            path: '/provider',
            active: false
        },
        {
            label: 'Impostazioni',
            path: '/configuration',
            active: false
        },
        {
            label: 'Logout',
            path: '/logout',
            active: false
        },
    ]

    export const ADMIN_TABS: NavbarTab[] = [
        {
            label: 'Clienti',
            path: '/customers',
            active: false
        },
        ...USER_TABS,
    ]
}