import { AlertCfg, MainStore, Provider, ProviderConfig } from '@/interfaces'
import AuthService from '@/services/AuthService';
import ProviderService from '@/services/ProviderService';
import { UtilsTab } from '@/utils';
import { ActionTree, createStore, GetterTree, MutationTree } from 'vuex'

const state: MainStore = {
  isAdmin: false,
  providerConfig: null,
  tabs: [],
  providers: [],
  alert: {
    type: null,
    message: null,
    toggle: false
  },
  isLoading: false
}

const mutations: MutationTree<MainStore> = {
  setUser(state: MainStore, isAdmin: boolean) {
    state.isAdmin = isAdmin;
    state.tabs = isAdmin ? UtilsTab.ADMIN_TABS : UtilsTab.USER_TABS;
  },
  setProviderConfig(state: MainStore, providerConfig: ProviderConfig | null) {
    state.providerConfig = providerConfig;
  },
  resetTabs(state: MainStore) {
    state.tabs = [];
  },
  setProviders(state: MainStore, providers: Provider[]) {
    state.providers = providers;
  },
  setAlert(state: MainStore, alert: Partial<AlertCfg>) {
    state.alert = {
      ...alert as AlertCfg,
      toggle: true
    };
  },
  setLoading(state: MainStore, isLoading: boolean) {
    state.isLoading = isLoading;
  }
}

const getters: GetterTree<MainStore, any> = {
  haveProvider(state: MainStore): boolean {
    return state.providerConfig != null ? state.providerConfig.authorized : false;
  }
}

const actions: ActionTree<MainStore, any> = {
  async setup({ commit }) {
    const { isAdmin, providerConfig } = await AuthService.setup();
    commit('setUser', isAdmin);
    commit('setProviderConfig', providerConfig);

    const providers = await ProviderService.find();
    commit('setProviders', providers);
  }
}

export default createStore({
  state,
  getters,
  mutations,
  actions,
  // modules: {
  // }
})
