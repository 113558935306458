import { Configuration, Provider, ProviderConfig, ProviderConfigBody, ProviderSupported } from "@/interfaces";
import axios from "axios";

class ProviderService {
    constructor() {}

    async generalSave(config: Configuration): Promise<void> {
        await axios.post('/provider/general-config', config);
    }

    async save(config: ProviderConfigBody): Promise<void> {
        await axios.post('/provider/config', config);
    }

    async find(): Promise<Provider[]> {
        const { data } = await axios.get<Provider[]>('/providers');
        return data;
    }

    async handleAuth(type: ProviderSupported): Promise<any> {
        const { data } = await axios.get('/providers/' + type + '/auth');
        return data;
    }

    async findByLogged(): Promise<ProviderConfig | null> {
        try {
            const { data } = await axios.get<ProviderConfig>('/provider/config');
            return data;
        } catch (e) {
            return null;
        }
    }
}

export default new ProviderService();