import { Customer } from "./general";

export enum ProviderSupported {
    FattureInCloud = 'fattureincloud'
}

export type FattureInCloudConfig = {
    clientId: string;
    clientSecret: string;
}

export type ProviderConfigBody = {
    type: ProviderSupported;
    
    // Fatture in cloud
    clientId?: string;
    clientSecret?: string;
}