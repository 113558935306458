export interface Credentials {
    email: string;
    password: string;
}

export type Tokens = {
    token: string;
    refreshToken: string;
    isAdmin: boolean;
}

export enum LoginType {
    Admin = 'panel-admin',
    User = 'users'
}