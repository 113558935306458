import AuthService from '@/services/AuthService';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "Logout" */ '../views/HomeView.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Logout" */ '../views/LoginView.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/admin/login',
    name: 'admin-login',
    component: () => import(/* webpackChunkName: "Logout" */ '../views/LoginView.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import(/* webpackChunkName: "Logout" */ '../views/InvoiceView.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import(/* webpackChunkName: "Logout" */ '../views/CustomerView.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/provider',
    name: 'provider',
    component: () => import(/* webpackChunkName: "Logout" */ '../views/ProviderView.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import(/* webpackChunkName: "Logout" */ '../views/ConfigurationView.vue'),
    meta: {
      auth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    const el = document.getElementById('app');
    if (el != null) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }
})

router.beforeEach((to, from, next) => {
  if (!AuthService.isLoggedIn() && to.meta != null && to.meta.auth === true) {
    return next({ path: '/login' });
  } else if (AuthService.isLoggedIn() && to.meta != null && to.meta.auth === false) {
    return next({ path: '/invoices' });
  }

  return next();
})

export default router
