import axios from 'axios';
import AuthService from '@/services/AuthService';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.timeout = 15000;

let isRefreshing: boolean = false;

let failedQueue: { resolve: Function; reject: Function }[] = [];
function processQueue(error: any, token: string | null) {
    failedQueue.forEach(({ resolve, reject }) => {
        if (error) {
            reject(error);
        } else {
            resolve(token);
        }
    });

    failedQueue = [];
}

axios.interceptors.request.use(
    config => ({...config, headers: {...config.headers, Authorization: `Bearer ${AuthService.getToken()}` }}),
    error => Promise.reject(error)
);

axios.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;
        if (error.response.status == 401 && !isRefreshing) {
            // Send refresh if not starting
            if (!isRefreshing) {
                originalRequest._retry = true;
                isRefreshing = true;

                return new Promise((resolve, reject) => {
                    AuthService.refreshToken()
                        .then((data) => {
                            resolve(axios(originalRequest))
                            processQueue(null, data.token);
                        })
                        .catch(err => {
                            processQueue(err, null);
                            AuthService.logout();
                            reject(err);
                        })
                        .finally(() => isRefreshing = false)
                })
            } else {
                return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
                    .then(() => axios(originalRequest))
                    .catch(err => Promise.reject(err));
            }
        }
        return Promise.reject(JSON.parse(JSON.stringify(error)));
    }
)