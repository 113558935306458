import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "common-layout" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  key: 0,
  class: "mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_MrUiKitNavbar = _resolveComponent("MrUiKitNavbar")!
  const _component_MrUiKitAlert = _resolveComponent("MrUiKitAlert")!
  const _component_InlineToast = _resolveComponent("InlineToast")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.tabs.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_MrUiKitNavbar, {
              logo: _ctx.logoHeader,
              items: _ctx.tabs,
              onNavigate: _ctx.onNavigate
            }, null, 8, ["logo", "items", "onNavigate"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_MrUiKitAlert, {
        modelValue: _ctx.alert.toggle,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.alert.toggle) = $event)),
        timeout: 4000,
        type: _ctx.alert.type,
        classes: ['position-fixed', 'alert-position', 'w-25']
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.alert.message), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "type"]),
      (_ctx.showNoProviderToast)
        ? (_openBlock(), _createBlock(_component_InlineToast, {
            key: 1,
            class: "bg-danger text-white"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Fornitore non configurato ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view)
    ])
  ]))
}