<template>
  <div class="inline-toast col-12">
    <slot />
  </div>
</template>

<style lang="scss">
.inline-toast {
  padding: 16px 20px;
  border-radius: 8px;
  margin: 10px 0;
}
</style>